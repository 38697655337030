import { isPercentage } from "src/utils/utils";

const Logo = ({ width = 94, height }) => {

    let renderHeight = height;
    let isWidthPercentage = isPercentage(width);
    if (!isWidthPercentage) {
        if (!renderHeight) {
            renderHeight = width / (94 / 54);
        } else if (!width || !height) {
            renderHeight = 54;
        }
    }

    return <svg width={width} height={renderHeight} viewBox="0 0 94 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_899_173)">
            <rect width="94" height="54" rx="8" fill="#3D8F81" />
            <g clip-path="url(#clip1_899_173)">
                <path d="M44.3147 42.9891V35.4655C44.3147 35.3876 44.359 35.3432 44.4258 35.3432H45.1594C45.2374 35.3432 45.2817 35.3988 45.3042 35.4543L45.4708 36.0657C45.8376 35.5323 46.4041 35.2322 47.149 35.2322C48.7269 35.2322 49.7831 36.3434 49.7831 37.9661C49.7831 39.5888 48.7274 40.6888 47.149 40.6888C46.4709 40.6888 45.9374 40.433 45.5818 39.9775V42.9891C45.5818 43.0559 45.5375 43.1002 45.4708 43.1002H44.4263C44.3596 43.1002 44.3153 43.0559 44.3153 42.9891H44.3147ZM47.071 39.4217C47.9377 39.4217 48.5266 38.8439 48.5266 37.9655C48.5266 37.0871 47.9377 36.4875 47.071 36.4875C46.2044 36.4875 45.593 37.0877 45.593 37.9543C45.593 38.8209 46.1932 39.4211 47.071 39.4211V39.4217Z" fill="#E7FC87" />
                <path d="M54.9055 35.4543C54.9279 35.3876 54.961 35.3433 55.0277 35.3433H55.8725C55.9392 35.3433 55.9836 35.3876 55.9836 35.4543V40.4555C55.9836 40.511 55.9392 40.5665 55.8725 40.5665H55.0165C54.9498 40.5665 54.9167 40.5222 54.8943 40.4555L54.7389 39.8665C54.372 40.3887 53.8055 40.6888 53.0718 40.6888C51.5046 40.7 50.449 39.5776 50.449 37.9661C50.449 36.3546 51.5046 35.2546 53.0718 35.2546C53.8055 35.2546 54.372 35.5435 54.7389 36.0551L54.9055 35.4549V35.4543ZM53.1498 39.4329C54.0276 39.4329 54.6278 38.844 54.639 37.9661C54.6278 37.0883 54.0276 36.5105 53.1498 36.5105C52.272 36.5105 51.7049 37.0883 51.7049 37.9661C51.7049 38.844 52.2938 39.4329 53.1498 39.4329Z" fill="#E7FC87" />
                <path d="M59.4617 36.521C59.1061 36.521 58.7505 36.7544 58.5615 37.1543V40.4547C58.5615 40.5215 58.5284 40.5658 58.4504 40.5658H57.406C57.3392 40.5658 57.2949 40.5215 57.2949 40.4547V35.4648C57.2949 35.3981 57.3392 35.3537 57.406 35.3537H58.1284C58.2171 35.3537 58.2619 35.4093 58.2726 35.4648L58.3837 35.8092C58.7168 35.476 59.1729 35.2427 59.7063 35.2427C60.0507 35.2427 60.3396 35.3313 60.5067 35.3762C60.5735 35.3986 60.6066 35.4541 60.5847 35.5203L60.3183 36.6096C60.3071 36.6539 60.274 36.6876 60.2184 36.6876C60.196 36.6876 60.1853 36.6764 60.1629 36.6764C60.0182 36.6096 59.7181 36.521 59.4629 36.521H59.4617Z" fill="#E7FC87" />
                <path d="M63.6956 40.6888C62.0841 40.6888 60.9954 39.5776 60.9954 37.9661C60.9954 36.1656 62.3287 35.2658 63.6737 35.2434C64.6408 35.2434 65.4519 35.5878 65.9965 36.4101C66.0302 36.4656 66.0302 36.5324 65.9853 36.5655L65.1961 37.2212C65.1736 37.2436 65.1518 37.2436 65.1181 37.2436C65.0845 37.2436 65.0401 37.2324 65.0183 37.1881C64.7182 36.7545 64.2627 36.4993 63.6956 36.4993C62.829 36.4993 62.2507 37.0883 62.2507 37.9661C62.2507 38.8439 62.8284 39.4329 63.6956 39.4329C64.3738 39.4329 64.7737 39.0997 64.9958 38.7996C65.0289 38.7553 65.0738 38.7329 65.1069 38.7329C65.14 38.7329 65.1624 38.7441 65.1849 38.7665L65.9073 39.4335C65.9404 39.4778 65.9628 39.5445 65.9185 39.6001C65.4631 40.2889 64.6963 40.6894 63.6956 40.6894V40.6888Z" fill="#E7FC87" />
                <path d="M69.4748 39.4997C70.0082 39.4884 70.4194 39.3219 70.6639 39.0885C70.7083 39.0554 70.7419 39.0442 70.775 39.0442C70.8081 39.0442 70.8305 39.0554 70.853 39.0773L71.5087 39.7554C71.553 39.7998 71.5642 39.8665 71.5199 39.922C71.0308 40.4443 70.3083 40.6776 69.4086 40.6776C67.7528 40.6888 66.6416 39.5883 66.6416 37.9661C66.6416 36.3439 67.7309 35.2546 69.3419 35.2546C70.9528 35.2546 72.0533 36.2323 72.0427 38.3554C72.0427 38.4221 72.009 38.4665 71.9316 38.4665H67.9867C68.1533 39.0779 68.6755 39.5002 69.4759 39.5002L69.4748 39.4997ZM70.7195 37.5213C70.586 36.8768 70.1081 36.4432 69.3413 36.4432C68.5745 36.4432 68.0966 36.8768 67.9631 37.5213H70.7189H70.7195Z" fill="#E7FC87" />
                <path d="M74.3088 40.4554C74.3088 40.5221 74.2645 40.5664 74.2089 40.5664H73.1533C73.0978 40.5664 73.0535 40.5221 73.0535 40.4554L73.0422 32.6535C73.0422 32.5868 73.0866 32.5425 73.1533 32.5425H74.1977C74.2645 32.5425 74.3088 32.5868 74.3088 32.6535V40.4554Z" fill="#E7FC87" />
                <path d="M34.7676 37.6164C33.7047 38.2401 32.4668 38.5974 31.1458 38.5974C29.8248 38.5974 28.5846 38.2401 27.5211 37.6158C25.508 36.4345 23.2548 35.7205 20.9203 35.7205H20.2842C21.9955 40.044 26.2148 43.0999 31.1452 43.0999C36.0757 43.0999 40.2933 40.044 42.0046 35.7205H41.3685C39.0334 35.7205 36.7808 36.4345 34.7671 37.6164H34.7676Z" fill="white" />
                <path d="M50.0427 10.5C46.4478 10.5 43.2343 12.1261 41.0932 14.6811L41.2105 14.8404C42.2627 16.2713 42.9841 17.8929 43.3448 19.6099C44.3757 16.9147 46.984 15.0002 50.0427 15.0002C54.0022 15.0002 57.2129 18.2109 57.2129 22.1705C57.2129 26.13 54.0022 29.3407 50.0427 29.3407C46.984 29.3407 44.3757 27.4257 43.3448 24.7311C42.9633 23.7332 42.9499 22.7151 42.8988 21.6651C42.8472 20.5943 42.6487 19.5308 42.3116 18.5133C41.9262 17.3499 41.3586 16.2528 40.6344 15.2644C40.4987 15.0799 40.3562 14.9004 40.2104 14.7243C40.4874 14.2963 40.6501 13.787 40.6501 13.239C40.6501 11.7267 39.4239 10.5006 37.9117 10.5006C37.0838 10.5006 36.3439 10.8691 35.8414 11.4496C34.4262 10.8388 32.8674 10.5 31.2289 10.5C24.7812 10.5 19.5557 15.7255 19.5557 22.1699C19.5557 28.6143 24.7812 33.8398 31.2289 33.8398C34.8227 33.8398 38.0351 32.2137 40.1756 29.6587L40.0583 29.4994C39.0055 28.0685 38.2847 26.4469 37.924 24.7299C36.8931 27.4251 34.2854 29.3401 31.2278 29.3401C27.2666 29.3401 24.0559 26.1294 24.0559 22.1699C24.0559 18.2104 27.2666 14.9997 31.2278 14.9997C34.2848 14.9997 36.8931 16.9147 37.924 19.6099C38.2948 20.5791 38.3285 21.6045 38.3778 22.6276C38.467 24.4876 38.9769 26.3308 39.9018 27.9496C40.124 28.3389 40.3691 28.7147 40.6344 29.0759C40.7808 29.2751 40.9345 29.4691 41.0932 29.6587C43.2343 32.2137 46.4478 33.8398 50.0427 33.8398C56.4876 33.8398 61.7126 28.6143 61.7126 22.1699C61.7126 15.7255 56.4876 10.5 50.0427 10.5Z" fill="white" />
                <path d="M47.5314 21.1474V22.2675L46.7326 21.8065V20.6863L44.9007 19.6284C44.8547 19.7956 44.8306 19.97 44.8306 20.1478V24.1152C44.8306 24.8186 45.2058 25.4681 45.815 25.8198L49.2506 27.8032C49.4351 27.9098 49.6331 27.9799 49.8356 28.022V22.4773L49.4363 22.2468L47.5314 21.1474Z" fill="#E7FC87" />
                <path d="M55.1245 18.9624C55.1245 18.9024 55.1245 18.8873 55.1245 18.8273L53.3374 17.7952L48.3301 20.6861L49.835 21.555L50.2344 21.7855L50.6338 21.555L55.1245 18.9624Z" fill="#E7FC87" />
                <path d="M52.5387 17.3342L50.9059 16.3913C50.7028 16.2741 50.474 16.2 50.239 16.1995C50.2362 16.1995 50.2334 16.1995 50.2306 16.1995C49.9955 16.1995 49.7667 16.2735 49.5636 16.3913L47.5314 17.5647L45.2659 18.8722V18.9166L47.5314 20.2246L52.5387 17.3336V17.3342Z" fill="#E7FC87" />
                <path d="M55.5687 19.6284L51.0337 22.2468L50.6343 22.4773V28.022C50.8368 27.9799 51.0348 27.9098 51.2193 27.8032L54.6549 25.8198C55.2641 25.4681 55.6393 24.8186 55.6393 24.1152V20.1478C55.6393 19.97 55.6147 19.7956 55.5692 19.6284H55.5687Z" fill="#E7FC87" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_899_173">
                <rect width="94" height="54" rx="8" fill="white" />
            </clipPath>
            <clipPath id="clip1_899_173">
                <rect width="54.7529" height="32.6" fill="white" transform="translate(19.5557 10.5)" />
            </clipPath>
        </defs>
    </svg>
}

export default Logo
