// ** Icon imports
import HomeIcon from 'mdi-material-ui/Home'
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import HistoryIcon from '@mui/icons-material/History';
import TaskIcon from '@mui/icons-material/Task';
import LockIcon from '@mui/icons-material/Lock';
import CampaignIcon from '@mui/icons-material/Campaign';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import HelpIcon from '@mui/icons-material/Help';
import PlaceIcon from '@mui/icons-material/Place';
import WidthWideIcon from '@mui/icons-material/WidthWide';
import TopicIcon from '@mui/icons-material/Topic'
import CommuteIcon from '@mui/icons-material/Commute';
import RedeemIcon from '@mui/icons-material/Redeem';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import CategoryIcon from '@mui/icons-material/Category';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PinDropIcon from '@mui/icons-material/PinDrop';
import StarIcon from '@mui/icons-material/Star';
import PublicIcon from '@mui/icons-material/Public';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import PaymentIcon from '@mui/icons-material/Payment';
import PaymentsIcon from '@mui/icons-material/Payments';
import ArticleIcon from '@mui/icons-material/Article';
import FeedbackIcon from '@mui/icons-material/Feedback';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import QuizIcon from '@mui/icons-material/Quiz';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

const navigation = () => {
  return [
    {
      title: 'Dashboard',
      icon: HomeIcon,
      path: '/dashboard'
    },
    {
      sectionTitle: "Website Settings",
    },
    {
      title: 'Website',
      icon: PublicIcon,
      children: [
        {
          title: 'Privacy Policy',
          icon: LockIcon,
          path: '/privacy-policy'
        },
        {
          title: 'Terms and Conditions',
          icon: TaskIcon,
          path: '/terms-conditions'
        },
        {
          title: 'Testimonials',
          icon: FormatQuoteIcon,
          path: '/testimonials'
        },
        {
          title: 'Contact Inquiry',
          icon: ContactPageIcon,
          path: '/contact-us'
        },
        {
          title: 'FAQS',
          icon: HelpIcon,
          path: '/faqs'
        },
        {
          title: 'Blog',
          icon: ArticleIcon,
          path: '/blog'
        },
        {
          title: 'Job Post',
          icon: Diversity3Icon,
          children: [
            {
              title: 'Career Categories',
              icon: CategoryIcon,
              path: '/career-categories'
            },
            {
              title: 'Career Section',
              icon: ReduceCapacityIcon,
              path: '/career-section'
            },
          ]
        },
        {
          title: 'Social Media',
          icon: ConnectWithoutContactIcon,
          path: '/social-media'
        }
      ]
    },
    {
      sectionTitle: "Knowledge Base",
    },
    {
      title: 'Users',
      icon: Diversity3Icon,
      children: [
        {
          title: 'Drivers',
          icon: LocalShippingIcon,
          path: '/driver'
        },
        {
          title: 'Customer',
          icon: PeopleIcon,
          path: '/customer'
        },
      ]
    },
    {
      title: 'Vehicle Type',
      icon: CommuteIcon,
      path: '/vehicle-type'
    },
    // {
    //   title: 'Rental Rides',
    //   icon: HistoryIcon,
    //   children: [
    //     {
    //       icon: LocalShippingIcon,
    //       title: 'Rides',
    //       path: '/active-rides'
    //     },
    //   ]
    // },
    {
      title: 'Category',
      icon: CategoryIcon,
      children: [
        {
          title: 'Parcel Category',
          icon: RedeemIcon,
          path: '/parcel-category'
        },
        {
          title: 'Business Category',
          icon: LocalMallIcon,
          path: '/business-category'
        },
        {
          title: 'Product Category',
          icon: CategoryIcon,
          path: '/product-category'
        },
      ]
    },

    {
      title: 'Fare Management',
      icon: CorporateFareIcon,
      children: [
        {
          title: 'Document',
          icon: TopicIcon,
          path: '/document'
        },
        {
          title: 'City Management',
          icon: LocationCityIcon,
          path: '/city-management'
        },
        {
          title: 'Price Management',
          icon: PriceChangeIcon,
          path: '/price-management'
        },

      ]
    },
    {
      title: 'Hub Address',
      icon: PlaceIcon,
      path: '/hub-address'
    },
    {
      title: 'Indicate Dropoff Location',
      icon: PinDropIcon,
      path: '/drop-locations'
    },
    {
      title: 'Refer a Friend',
      icon: CampaignIcon,
      path: '/refer-friend'
    },
    {
      title: 'Notifications',
      icon: NotificationsActiveIcon,
      path: '/notifications'
    },
    {
      title: 'FeedBack',
      icon: FeedbackIcon,
      children: [
        {
          title: 'Feedback Category',
          icon: FeedbackIcon,
          path: '/feedback-category'
        },
        {
          title: 'Feedback',
          icon: DynamicFeedIcon,
          path: '/feedback'
        },
      ]
    },
    {
      title: 'Payment History',
      icon: HistoryIcon,
      children: [
        {
          title: 'Payment',
          icon: PaymentIcon,
          path: '/payment'
        },
        {
          title: 'Extra',
          icon: PaymentsIcon,
          //path: '/earning-history'
        },
      ]
    },
    {
      sectionTitle: "Customer",
    },
    {
      title: 'Size Chart',
      icon: WidthWideIcon,
      path: '/size-chart'
    },
    {
      title: 'Help',
      icon: QuizIcon,
      path: '/help'
    },
    {
      title: 'Orders',
      icon: ShoppingCartCheckoutIcon,
      path: '/orders'
    },
    {
      title: 'Ratings & Reviews',
      icon: StarIcon,
      path: '/ratings-reviews'
    },
    {
      title: 'Coupons',
      icon: ConfirmationNumberIcon,
      path: '/coupons'
    }
  ]
}

export default navigation
