//export const API_BASE_URL = "http://192.168.29.54:3000"
export const API_BASE_URL = "https://api.goparcel247.com"

export const MEDIA_URL = "https://d1hj3r7039erwq.cloudfront.net"
export const ApiEndPoints = {
    AUTH: {
        login: `${API_BASE_URL}/api/v1/admin/auth/login`,
        me: `${API_BASE_URL}/api/v1/admin/auth/me`,
        forgot: `${API_BASE_URL}/api/v1/admin/auth/forgot/password`,
        verifotp: `${API_BASE_URL}/api/v1/admin/auth/verify/otp`,
        reset: `${API_BASE_URL}/api/v1/admin/auth/reset/password`
    },
    DASHBOARD: {
        count: `${API_BASE_URL}/api/v1/admin/user/counts`
    },
    USERS: {
        list: (type) => `${API_BASE_URL}/api/v1/admin/user/list/${type}`,
        listbydriver: id => `${API_BASE_URL}/api/v1/admin/orders/driver/history/${id}`,
        listbycustomer: id => `${API_BASE_URL}/api/v1/admin/orders/customer/history/${id}`,
        map: id => `${API_BASE_URL}/api/v1/admin/orders/${id}`,
        statusedit: (id) => `${API_BASE_URL}/api/v1/admin/user/${id}`,
        create: `${API_BASE_URL}/api/v1/admin/user`,
        listbyid: (id) => `${API_BASE_URL}/api/v1/admin/user/${id}`,
        createvehical: (id) => `${API_BASE_URL}/api/v1/admin/user/vehicles/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/user/${id}`,
        orderbyid: (id) => `${API_BASE_URL}/api/v1/admin/orders/${id}`,
        expressDelivery: (id) => `${API_BASE_URL}/api/v1/admin/user/${id}`
    },
    DRIVER_VEHICALS: {
        list: (type) => `${API_BASE_URL}/api/v1/admin/user/vehicles/${type}`,
        verifyvehicle: (id) => `${API_BASE_URL}/api/v1/admin/user/vehicle/${id}`
    },
    PRIVACY_POLICY: {
        list: `${API_BASE_URL}/api/v1/admin/legalcontent/privacy_policy`,
        edit: `${API_BASE_URL}/api/v1/admin/legalcontent/privacy_policy`
    },
    Terms_AND_CONDITIONS: {
        list: `${API_BASE_URL}/api/v1/admin/legalcontent/terms_and_conditions`,
        edit: `${API_BASE_URL}/api/v1/admin/legalcontent/terms_and_conditions`
    },
    Testimonials: {
        list: `${API_BASE_URL}/api/v1/admin/testimonials`,
        create: `${API_BASE_URL}/api/v1/admin/testimonials`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/testimonials/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/testimonials/${id}`
    },
    ContactUs: {
        list: `${API_BASE_URL}/api/v1/admin/contact-us`,
        reply: (id) => `${API_BASE_URL}/api/v1/admin/contact-us/${id}`
    },
    HUB_ADDRESS: {
        list: `${API_BASE_URL}/api/v1/admin/hubaddress`,
        create: `${API_BASE_URL}/api/v1/admin/hubaddress`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/hubaddress/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/hubaddress/${id}`
    },
    SIZE_CHART: {
        list: `${API_BASE_URL}/api/v1/admin/sizechart`,
        create: `${API_BASE_URL}/api/v1/admin/sizechart`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/sizechart/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/sizechart/${id}`
    },
    FAQ: {
        list: `${API_BASE_URL}/api/v1/admin/faqs`,
        create: `${API_BASE_URL}/api/v1/admin/faqs`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/faqs/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/faqs/${id}`
    },
    DOCUMENT: {
        list: `${API_BASE_URL}/api/v1/admin/documents`,
        create: `${API_BASE_URL}/api/v1/admin/documents`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/documents/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/documents/${id}`
    },
    PINCODE: {
        list: `${API_BASE_URL}/api/v1/admin/pincodes`,
        create: `${API_BASE_URL}/api/v1/admin/pincodes`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/pincodes/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/pincodes/${id}`
    },
    ORDERS_LIST: {
        list: `${API_BASE_URL}/api/v1/admin/orders`,
        statusedit: (id) => `${API_BASE_URL}/api/v1/admin/orders/${id}`,
        listbyid: (id) => `${API_BASE_URL}/api/v1/admin/orders/${id}`,
    },
    VEHICLE_TYPE: {
        list: `${API_BASE_URL}/api/v1/admin/vehicle/types`,
        create: `${API_BASE_URL}/api/v1/admin/vehicle/types`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/vehicle/types/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/vehicle/types/${id}`
    },
    PARCEL_CATEGORY: {
        list: `${API_BASE_URL}/api/v1/admin/categories/parcel`,
        create: `${API_BASE_URL}/api/v1/admin/categories/parcel`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/categories/parcel/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/categories/parcel/${id}`
    },
    BUSINESS_CATEGORY: {
        list: `${API_BASE_URL}/api/v1/admin/categories/business`,
        create: `${API_BASE_URL}/api/v1/admin/categories/business`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/categories/business/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/categories/business/${id}`
    },
    PRODUCT_CATEGORY: {
        list: `${API_BASE_URL}/api/v1/admin/categories/product`,
        create: `${API_BASE_URL}/api/v1/admin/categories/product`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/categories/product/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/categories/product/${id}`
    },
    CITY_MANAGEMENT: {
        list: `${API_BASE_URL}/api/v1/admin/city`,
        create: `${API_BASE_URL}/api/v1/admin/city`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/city/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/city/${id}`
    },
    PRICE_MANAGEMENT: {
        list: `${API_BASE_URL}/api/v1/admin/price`,
        create: `${API_BASE_URL}/api/v1/admin/price`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/price/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/price/${id}`,
        listbyid: (id) => `${API_BASE_URL}/api/v1/admin/price/${id}`,
    },
    GET_REGION: {
        country: `https://api.countrystatecity.in/v1/countries`,
        state: `https://api.countrystatecity.in/v1/countries/`,
        city: `https://api.countrystatecity.in/v1/countries/`
    },
    DROP_OFF_LOCATION: {
        list: `${API_BASE_URL}/api/v1/admin/drop_locations`,
        create: `${API_BASE_URL}/api/v1/admin/drop_locations`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/drop_locations/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/drop_locations/${id}`,
    },
    COUNTRY: {
        list: `${API_BASE_URL}/api/v1/misc/countries`
    },
    NOTIFICATION: {
        list: `${API_BASE_URL}/api/v1/admin/notifications`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/notifications/${id}`,
    },
    REFER_FRIEND: {
        list: `${API_BASE_URL}/api/v1/admin/refer_friend`,
        edit: (type) => `${API_BASE_URL}/api/v1/admin/refer_friend/${type}`,
    },
    CAREER_CATEGORY: {
        list: `${API_BASE_URL}/api/v1/admin/categories/career`,
        create: `${API_BASE_URL}/api/v1/admin/categories/career`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/categories/career/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/categories/career/${id}`
    },
    CAREER_SECTION: {
        list: `${API_BASE_URL}/api/v1/admin/careers`,
        create: `${API_BASE_URL}/api/v1/admin/careers`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/careers/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/careers/${id}`
    },
    BLOG: {
        list: `${API_BASE_URL}/api/v1/admin/blogs`,
        create: `${API_BASE_URL}/api/v1/admin/blogs`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/blogs/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/blogs/${id}`
    },
    FEEDBACK_CATEGORY: {
        list: `${API_BASE_URL}/api/v1/admin/categories/feedback`,
        create: `${API_BASE_URL}/api/v1/admin/categories/feedback`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/categories/feedback/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/categories/feedback/${id}`
    },
    FEEDBACK: {
        list: `${API_BASE_URL}/api/v1/admin/feedbacks`,
        create: `${API_BASE_URL}/api/v1/admin/feedbacks`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/feedbacks/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/feedbacks/${id}`
    },
    HELP: {
        list: `${API_BASE_URL}/api/v1/admin/helps`,
        create: `${API_BASE_URL}/api/v1/admin/helps`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/helps/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/helps/${id}`
    },
    FOOTER: {
        list: `${API_BASE_URL}/api/v1/admin/categories/footer`,
        create: `${API_BASE_URL}/api/v1/admin/categories/footer`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/categories/footer/${id}`,
    },
    COUPONS: {
        list: `${API_BASE_URL}/api/v1/admin/coupons`,
        create: `${API_BASE_URL}/api/v1/admin/coupons`,
        edit: (id) => `${API_BASE_URL}/api/v1/admin/coupons/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/admin/coupons/${id}`
    }
}
