import React, { Suspense } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import FallbackSpinner from './@core/components/spinner'

import AuthGuard from 'src/@core/components/auth/AuthGuard'
import UserLayout from './layouts/UserLayout'
import BlankLayout from './@core/layouts/BlankLayout'
// import BlankLayoutWithAppBar from './@core/layouts/BlankLayoutWithAppBar'
import AclGuard from './@core/components/auth/AclGuard'
import GuestGuard from './@core/components/auth/GuestGuard'
import { defaultACLObj } from './configs/acl'
import AuthLayout from './layouts/AuthLayout'

//website 
const CareerCategoriesPage = React.lazy(() => import('./pages/website-content/career-section/career-categories'))
const CareerPage = React.lazy(() => import('./pages/website-content/career-section'))
const FaqsPage = React.lazy(() => import('./pages/website-content/faqs'))
const TermsandConditionPage = React.lazy(() => import('./pages/website-content/termsandcondition'))
const TestimonialsPage = React.lazy(() => import('./pages/website-content/testimonials'))
const ContactUsPage = React.lazy(() => import('./pages/website-content/contact-us'))
const PrivacyPolicyPage = React.lazy(() => import('./pages/website-content/privacy-policy'))
const BlogPage = React.lazy(() => import('./pages/website-content/blog'))
const SocialMediaPage = React.lazy(() => import('./pages/website-content/social-media'))

//admin
const HomePage = React.lazy(() => import('./pages/home'))
const DashboardPage = React.lazy(() => import('./pages/dashboard'))
const LoginPage = React.lazy(() => import('./pages/login'))
const ForgotPassword = React.lazy(() => import('./pages/login/forgotpassword'))
const DriverPage = React.lazy(() => import('./pages/driver'))
const DriverDetailPage = React.lazy(() => import('./pages/driver/DriverDetail'))
const DriverHistoryPage = React.lazy(() => import('./pages/driver/driverHistory'))
const DriverHistoryDetailsPage = React.lazy(() => import('./pages/driver/DriverHistoryDetails'))
const BikePage = React.lazy(() => import('./pages/driver/vehicals-users/bike'))
const CarPage = React.lazy(() => import('./pages/driver/vehicals-users/car'))
const TruckPage = React.lazy(() => import('./pages/driver/vehicals-users/truck'))
const VanPage = React.lazy(() => import('./pages/driver/vehicals-users/van'))
const CustomerPage = React.lazy(() => import('./pages/customer'))
const RideHistoryPage = React.lazy(() => import("./pages/customer/customerHistory"))
const CustomermapPage = React.lazy(() => import("./pages/customer/customermap"))
const HubAddressPage = React.lazy(() => import('./pages/hub-address'))
const HubAddressDetailsPage = React.lazy(() => import('./pages/hub-address/hubAddressDetailsPage'))
const SizechartPage = React.lazy(() => import('./pages/size-chart'))
const DocumentPage = React.lazy(() => import('./pages/document'));
const PincodePage = React.lazy(() => import('./pages/pincode'))
const OrdersPage = React.lazy(() => import('./pages/orders'))
const VehicleTypePage = React.lazy(() => import('./pages/vehicle-type'))
const ParcelCategoryPage = React.lazy(() => import('./pages/parcel-category'))
const BusinessCategoryPage = React.lazy(() => import('./pages/business-category'))
const ProductCategoryPage = React.lazy(() => import('./pages/product-category'))
const CityManagementPage = React.lazy(() => import('./pages/city-management'))
const PriceManagementPage = React.lazy(() => import('./pages/price-management'))
const PricedetailPage = React.lazy(() => import('./pages/price-management/pricedetailPage'))
const DropoffLocationPage = React.lazy(() => import('./pages/drop-off-location'))
const ActiveRidesPage = React.lazy(() => import('./pages/rental-rides'))
const RatingReviewPage = React.lazy(() => import('./pages/rating-review'))
const NotificationPage = React.lazy(() => import('./pages/notification'))
const ReferFriendPage = React.lazy(() => import('./pages/refer-a-friend'))
const PaymentPage = React.lazy(() => import('./pages/payment'))
const FeedBackPage = React.lazy(() => import('./pages/feedback'))
const FeedBackCategory = React.lazy(() => import('./pages/feedback/feedback-category'));
const HelpPage = React.lazy(() => import('./pages/help'))
const CouponsPage = React.lazy(() => import('./pages/coupons'))

const Page401 = React.lazy(() => import('./pages/401'))
const Page404 = React.lazy(() => import('./pages/404'))

const Guard = ({ children, authGuard, guestGuard }) => {
  if (guestGuard) {
    return <GuestGuard fallback={<FallbackSpinner />}>{children}</GuestGuard>
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>
  } else {
    return <AuthGuard fallback={<FallbackSpinner />}>{children}</AuthGuard>
  }
}

function App() {
  const aclAbilities = defaultACLObj

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <AclGuard aclAbilities={aclAbilities}>
        <Routes>
          <Route element={<BlankLayout><Outlet /></BlankLayout>}>
            <Route path='/401' element={<Page401 />} />
            <Route path='/404' element={<Page404 />} />

            <Route element={<AuthLayout><Outlet /></AuthLayout>}>
              <Route element={<Guard guestGuard><Outlet /></Guard>}>
                <Route path='/login' element={<LoginPage />}></Route>
                <Route path='/forgot-password' element={<ForgotPassword />} />
              </Route>
            </Route>
          </Route>

          <Route element={<UserLayout><Outlet /></UserLayout>}>
            <Route element={<Guard authGuard><Outlet /></Guard>}>
              <Route path='' element={<HomePage />} />
              <Route path='/dashboard' element={<DashboardPage />} />
              <Route path='/driver' element={<DriverPage />} />
              <Route path='/driver/:driverId' element={<DriverDetailPage />} />
              <Route path='/driver/history/:driverId' element={<DriverHistoryPage />} />
              <Route path='/driver/history/:driverId/details' element={<DriverHistoryDetailsPage />} />

              <Route path='/driver-bike' element={<BikePage />} />
              <Route path='/driver-car' element={<CarPage />} />
              <Route path='/driver-truck' element={<TruckPage />} />
              <Route path='/driver-van' element={<VanPage />} />
              <Route path='/customer' element={<CustomerPage />} />
              <Route path='/customer/:rideId' element={<RideHistoryPage />} />
              <Route path='/customer/history/:CustomerId' element={<CustomermapPage />} />

              <Route path='/hub-address' element={<HubAddressPage />} />
              <Route path='/hub-address/:id' element={<HubAddressDetailsPage />} />
              <Route path='/size-chart' element={<SizechartPage />} />
              <Route path='/document' element={<DocumentPage />} />
              <Route path='/pincode' element={<PincodePage />} />
              <Route path='/orders' element={<OrdersPage />} />
              <Route path='/vehicle-type' element={<VehicleTypePage />} />
              <Route path='/parcel-category' element={<ParcelCategoryPage />} />
              <Route path='/business-category' element={<BusinessCategoryPage />} />
              <Route path='/product-category' element={<ProductCategoryPage />} />
              <Route path='/city-management' element={<CityManagementPage />} />
              <Route path='/price-management' element={<PriceManagementPage />} />
              <Route path='/price-management/:id' element={<PricedetailPage />} />
              <Route path='/drop-locations' element={<DropoffLocationPage />} />
              <Route path='/active-rides' element={<ActiveRidesPage />} />
              <Route path='/ratings-reviews' element={<RatingReviewPage />} />
              <Route path='/notifications' element={<NotificationPage />} />
              <Route path='/refer-friend' element={<ReferFriendPage />} />
              <Route path='/payment' element={<PaymentPage />} />
              <Route path='/feedback' element={<FeedBackPage />} />
              <Route path='/feedback-category' element={<FeedBackCategory />} />
              <Route path='/help' element={<HelpPage />} />
              <Route path='/coupons' element={<CouponsPage />} />
              {/* website */}
              <Route path='/career-section' element={<CareerPage />} />
              <Route path='/career-categories' element={<CareerCategoriesPage />} />
              <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
              <Route path='/terms-conditions' element={<TermsandConditionPage />} />
              <Route path='/testimonials' element={<TestimonialsPage />} />
              <Route path='/contact-us' element={<ContactUsPage />} />
              <Route path='/faqs' element={<FaqsPage />} />
              <Route path='/blog' element={<BlogPage />} />
              <Route path='/social-media' element={<SocialMediaPage />} />
            </Route>
          </Route>

          {/* If no route found redirect it to --> /404 */}
          <Route path='*' element={<Navigate to='/404' replace />} />

        </Routes>
      </AclGuard>
    </Suspense>
  );
}

export default App;
